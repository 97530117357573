import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CJumbotron,
  CBreadCrumb,
  LWrap2,
  CSectTitle,
  CColorCard,
  CLabelCard,
  LRecruitUniqueSect,
  CVideoMedia,
  CVideo,
  AssetImage,
  CRecruitJumbtron,
  CTextImgCard,
  RecruitNews,
  Vertical,
  CBtnList,
  CHeroMedia,
  CHotelList,
  ContainerCrumbs,
  CFaqList,
  CFaqListSl,
} from "../../../components/_index";
import { useMobile } from '../../../utils/use-series';
import { size } from "lodash";
import { title } from "process";
import '../../../assets/_sass/page/recruit/faq.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "よくあるご質問",
      src: "/recruit/faq/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect05 u_bgWhite recruit-font">
        <LWrap2>
        <ContainerCrumbs data={crumbsData} />
        <div className="r-faq-head-container">
            <div className="r-faq-head-left">
              <div className="r-faq-head-jp">よくあるご質問</div>
              <div className="r-faq-head-en">FAQ</div>
            </div>
            <div className="r-faq-head-right">
            <div className="r-faqdata-head-article-a">
              <span className="r-faq-head-article-title-a">応募方法について</span>
              <p><br /></p>
              <CFaqListSl
                hash=""
                data={[
                  {
                    q: 'どのように応募すればいいですか',
                    a: '新卒の方はマイナビよりご応募ください。キャリア入社の方は弊社ホームページよりご応募をお願いいたします。'
                  
                  },
                  {
                    q: '選考の流れはどのようになっていますか',
                    a: <>新卒：マイナビよりエントリー→勤務希望エリアの会社説明会に参加→書類選考→人事面接→総合適性テスト→最終面接<br />キャリア：書類選考→面接（約2回）※想定役職により総合適性テストを受検いただく場合がございます</>                 
                    ,
                  },
                  {
                    q: '外国籍でも応募できますか',
                    a: '国籍を問わずご応募いただけます。'                  
                    ,
                  },
                  {
                    q: 'どのような人財を求めていますか',
                    a: '当社が大切にしている「6つの力」に共感し、一緒にチャレンジしていただける方。'                  
                    ,
                  },
                  {
                    q: '応募に必要な資格はありますか',
                    a: '特にございませんが、留学生の方は応募時原則N2以上が必要となります。'                  
                    ,
                  },
                ]}
              />
            </div>
            <div className="r-faqdata-head-article-a">
              <span className="r-faq-head-article-title-a">勤務地や配属先について</span>
              <p><br /></p>
              <CFaqListSl
                hash=""
                data={[
                  {
                    q: '勤務地は選べますか',
                    a: <>応募時にお選びいただいた勤務希望エリアは確約となります。<br />
                    （①札幌 ②仙台 ③東京･横浜 ④名古屋 ⑤京都･大阪･神戸 ⑥広島 ⑦福岡 ⑧沖縄　　※開業予定：舞浜、那覇）</>
                  
                  },
                  {
                    q: '勤務ホテルは選べますか',
                    a: <>新卒：面接時および内定後にご希望をお伺いしますが、エリア内に複数のホテルがある場合はいずれかのホテルへの配属となります。<br />
                    キャリア：応募時にご自身で選択していただきます。</>                 
                    ,
                  },
                  {
                    q: '部署は選べますか',
                    a: <>新卒：面接時および内定後にご希望をお伺いしますが、ホテル内に複数の部署がある場合はいずれかの部署への配属となります。<br />
                        キャリア：応募時にご自身で選択していただきます。</>                 
                    ,
                  },
                  {
                    q: '転勤はありますか',
                    a: 'エリア運営職としてご入社いただいた方は、全国転勤はございません。但しエリア内でのホテルの異動はございます。全国転勤を希望する場合は、社内試験を経てナショナル運営職や総合職へ転換いただきます。'                  
                    ,
                  },
                  {
                    q: 'エリア運営職・ナショナル運営職・総合職の違いはなんですか',
                    a: <>エリア運営職：異動はエリア内に限定され、各ホテル内で主に接遇・調理・管理業務を担っていただきます。<br />
                    <br />
                    ナショナル運営職：社内試験を経て転換し、活躍の場を全国に広げることができます。業務内容はエリア運営職と変わりません。<br />
                    <br />
                    総合職：社内試験を経て転換。勤務地はナショナル運営職と同じく全国ですが、接遇のみならず本社業務に携わる等、経営者育成の為幅広い業務を経験いただきます<br />
                    新卒入社の方は原則エリア運営職からのスタート（採用年により総合職の募集あり）となり、キャリア入社の方は個別にご相談させていただきます。</>               
                    ,
                  },
                  {
                    q: '職種について教えてください',
                    a: '弊社では、ホテル勤務者は「ホテル運営（宿泊･料飲･宴会婚礼･ホテル内管理業務）」「調理」の2職種にわかれ、本社勤務者は「企画･業務」となります。'                  
                    ,
                  },
                ]}
              />
            </div>

            <div className="r-faqdata-head-article-a">
              <span className="r-faq-head-article-title-a">勤務について</span>
              <p><br /></p>
              <CFaqListSl
                hash=""
                data={[                  
                  {
                    q: '勤務時間について教えてください',
                    a: <>ホテル運営･調理：1ヶ月単位の変形労働時間制となり、所定労働時間は1日7時間30分、年間1,912.5時間です。<br />
                    <br />
                    企画･業務：フレックスタイム制となり、所定労働時間は1日7時間45分、年間1,914.25時間です。</>                 
                    ,
                  },
                  {
                    q: '残業について教えてください',
                    a: '繁閑の差はありますが、社内の月平均所定外労働時間は11.2時間です（2023年度実績）'                  
                    ,
                  },
                  {
                    q: '年間の休日数について教えてください',
                    a: <>ホテル運営･調理：110日（月8日以上／各月の休日はセクションごとに指定）<br />
                    <br />
                    企画･業務：118日（会社の年間カレンダーによる／原則、土・日・祝日を休日としますが、一部の祝日は出勤日となります）</>               
                    ,
                  },
                  {
                    q: '長期の休暇はありますか',
                    a: '4連休以上を取得いただきます（特別連続公休）。最大の休暇日数は上長と相談のうえ決定いたします。'                  
                    ,
                  },
                  {
                    q: '英語力はどれくらい必要ですか',
                    a: '採用時の条件はございませんが、外国人ゲストの比率が高いエリアも多くありますので、ぜひ積極的に学習いただければと思います。'                  
                    ,
                  },
                  {
                    q: '人事異動について教えてください',
                    a: '異動の回数やスパンは人により異なります。年に一度提出いただく「自己申告書」の中で、ご自身が希望するキャリアパスをお伺いしますが、すべての希望が叶うものではございません。'                  
                    ,
                  },
                  {
                    q: '勤務の評価はどのように行われますか',
                    a: '半年ごとに個人で目標設定を行い、日常業務のなかで目標の再確認や修正を行います。半年後に自己評価および上長による評価を実施し、その結果についてはフィードバック面談が行われます。'                  
                    ,
                  },
                  {
                    q: '育児休業などの各種制度について教えてください',
                    a: <><a className="r_faq_link" href="/recruit/careerpath/#fromFaq">各種制度</a>をご覧ください。</>                
                    ,
                  },
                ]}
              />
            </div>

            <div className="r-faqdata-head-article-a">
              <span className="r-faq-head-article-title-a">福利厚生について</span>
              <p><br /></p>
              <CFaqListSl
                hash=""
                data={[
                  {
                    q: '福利厚生について教えてください',
                    a:<><a className="r_faq_link" href="/recruit/careerpath/#fromFaq">福利厚生</a>をご覧ください。</>                   
                    ,
                  },
                  {
                    q: '住宅手当や社員寮はありますか',
                    a: '新卒採用対象住宅補助がございます（該当者のみ支給／詳細は会社説明会にてお伝えしております）。社員寮はございません。転勤の際には別途規程により借上社宅がございます。'                  
                    ,
                  },
                  {
                    q: '食事手当はありますか',
                    a: '社員食堂が無い場所に勤務する場合、食事手当がございます。また、深夜勤務者には別途深夜食事手当がございます。'                  
                    ,
                  },
                ]}
              />
            </div>

            <div className="r-faqdata-head-article-a">
              <span className="r-faq-head-article-title-a">パート・アルバイト</span>
              <p><br /></p>
              <CFaqListSl
                hash=""
                data={[
                  {
                    q: '正社員登用はありますか',
                    a: '社員（正社員または契約社員）登用制度がございます。',
                  
                  },
                ]}
              />
            </div>            
          </div>
        </div>
        </LWrap2>
      </section>
    </LayoutSl>

  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
